@use 'variables';
@use 'fonts';

.mc__field-container {
    width: 100%;
}

.mc__field-container input {
    width: 45%;
    height: 50px;
    margin-top: calc(variables.$inner-section-margin / 3);
    margin-bottom: calc(variables.$inner-section-margin / 3);
    background-color: variables.$background-color;
    border: 1px solid variables.$orangeish-accent-color;
    color: variables.$orangeish-accent-color;
    padding: 25px;
    // opacity: 0.65;
}

.submitBtn {
    background: variables.$button-bgrnd;
    border: none;
    // border-radius: 2px;
    width: 100px;
    height: auto;
    padding: 5px;
    color: white;
    font-family: variables.$font-family;
    font-size: large;
}

.mc__alert {
    color: variables.$paragraph-color;
}

.mc__alert--error {
    color: #b30000;
}

@media only screen and (max-width: 768px) {
    .mc__field-container input {
        width: 95%;
        height: 40px;
    }

    .submitBtn {
        width: 30%;
    }
}