@use 'variables';
@use 'fonts';

.ThreeDView {
    font-family: sans-serif;
    text-align: center;
    height: 75vh;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 0px;
  }
  
  body {
    padding: 0;
    margin: 0;
  }