@use 'variables';
@use 'fonts';

.image-card {
    background: variables.$background-color;
    border: none;
    margin-top: variables.$inner-section-margin;
    margin-bottom: calc(variables.$inner-section-margin / 3);
    height: calc(100% - 15px);
}

.image-card .avatar {
    background: linear-gradient(180deg, #221B2D 44.85%, #4B3A5C 121.56%);
    border-radius: 64px;  
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #4B3A5C 0%, #2F0588 100%);
}

.image-card  .title {
    font-family: variables.$font-family;
    color: white;
    font-size: 40px;
}

.image-card  .subtitle {
    font-size: 24px;
    color: variables.$orangeish-accent-color;
    margin-bottom: 8px;
}

.image-card .card-btn,
.image-card .card-btn:hover,
.image-card .card-btn:focus {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.simple-card {
    background: variables.$background-color;
    border: none;
    margin-top: variables.$inner-section-margin;
    margin-bottom: calc(variables.$inner-section-margin / 3);
    height: calc(100% - 15px);
}
  
.simple-card .title {
    font-family: variables.$font-family;
    color: white;
    font-size: 40px;
}

.simple-card .subtitle {
    font-size: 24px;
    color: variables.$orangeish-accent-color;
    margin-bottom: 8px;
}

.simple-card .card-btn {
    border: 1px solid orange;
    border-radius: 2px;
    width: auto;
    height: auto;
    padding: 10px;
    color: white;
    font-family: variables.$font-family;
    font-size: large;
}

.simple-card .card-btn:hover,
.simple-card .card-btn:focus {
    background: variables.$button-bgrnd;
    border: none;
    border-radius: 2px;
    width: auto;
    height: auto;
    padding: 10px;
    color: white;
    font-family: variables.$font-family;
    font-size: large;
}