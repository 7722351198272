$background-color: #17111c !default;
$heading-color: #FFEED5;
$paragraph-color: silver;
$orangeish-accent-color: #FFBE5A;
$strong-orange-color: #FF785A 80.65%;

$font-family: 'Staatliches', cursive;

$section-margin: 180px;
$inner-section-margin: 60px;
$mobile-section-margin: $inner-section-margin * 2;

$section-width: 65vw;
$mobile-section-width: 80vw;

$text-gradient-bgrnd-image: linear-gradient(
    180deg, 
    #FFEED5 0%, 
    $orangeish-accent-color 41.59%, 
    #FF785A 80.65%
    );
$webkit-background-clip: text;
$webkit-text-fill-color: transparent;

$footer-gradient: linear-gradient(
    #17111c, #1a1320, #331f00
);

$translucent-bgrnd: linear-gradient(
    rgb(23, 17, 28, 0.5),
    rgb(23, 17, 28, 0.2),
    rgb(23, 17, 28, 0)
);

$button-bgrnd: linear-gradient(
    #FF785A 60%,
    $orangeish-accent-color
);