@use 'variables';
@use 'fonts';

.wallet-integration {
    margin-top: calc(variables.$inner-section-margin / 3);
}

.connectBtn,
.disconnectBtn {
    background: variables.$button-bgrnd;
    border: none;
    border-radius: 2px;
    width: auto;
    height: auto;
    padding: 10px;
    color: white;
    font-family: variables.$font-family;
    font-size: large;
}

.wallet-address-text {
    color: white;
    font-size: 15px;
}

.connected-dot,
.error-dot {
    height: 11px;
    width: 11px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
}

.connected-dot {
    background-color: green;
}

.error-dot {
    background-color: red;
}

.modal-lid {
    background-color: variables.$background-color;
}

.modal-lid h4 {
    background-image: variables.$text-gradient-bgrnd-image;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-family: variables.$font-family;
    font-size: larger;
}

.modal-body {
    background-color: white;
}