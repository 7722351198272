@use 'variables';
@use 'fonts';

.NavBar {
    background: variables.$translucent-bgrnd;
}

.justify-content-center {
    flex: 1;
}

.header-img {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 5px;
}

img {
    margin-top: 5px;
}

.link-text {
    color: variables.$heading-color !important;
    font-family: 'Staatliches', cursive;
    font-size: large;
}

.detect-device-view {
    padding-bottom: 0px !important;
    height: 0px !important;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

@media only screen and (max-width: 768px) {
    .navbar-nav {
        height: 85vh !important;
        text-align: center;
    }

    .navbar-toggler {
        padding-top: 30px;
    }

    .navbar-brand {
        margin-left: 45%;
    }

    .navbar-brand img {
        width:auto;
        max-height:50px !important;
        margin-top: 0px;
        padding-top: 0px;
    }

    .link-text {
        color: white !important;
        // font-family:;
        font-size: xx-large;
    }
}