@use 'variables';
@use 'fonts';

body {
  background-color: variables.$background-color;
}

.vector1-range {
  background-image: url('../assets/Vector1.svg');
  background-size: 100vw 80vh;
  background-repeat: no-repeat;
  padding-bottom: 100px;
}

.jumbo-text {
  margin-top: variables.$section-margin;
  width: 80vw;
}

.jumbo-text h1 {
  font-size: 100px;
  font-family: "Staatliches", cursive;
  color: variables.$heading-color;
}

.jumbo-text h3 {
  font-size: 40px;
  background-image: variables.$text-gradient-bgrnd-image;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  font-family: variables.$font-family;
}

.jumbo-text h6 {
  width: 600px;
  color: variables.$paragraph-color;
}

.features {
  margin-top: variables.$section-margin;
  text-align: center;
  width: variables.$section-width;
}

h2 {
  font-size: 65px;
  font-family: "Staatliches", cursive;
  color: variables.$heading-color;
}

h3 {
  font-size: 30px;
  background-image: variables.$text-gradient-bgrnd-image;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  font-family: variables.$font-family;
}

.features-detail-row {
  margin-top: variables.$inner-section-margin;
}

.features-detail-row h4 {
  font-family: variables.$font-family;
  color: variables.$heading-color;
}

.features-detail-row h6 {
  color: variables.$paragraph-color;
}

.left-column {
  border-right: 1px solid variables.$orangeish-accent-color;
}

.vector6-range {
  background-image: url('../assets/Vector6.png');
  background-size: 25vw 70vh;
  background-repeat: no-repeat;
  background-position: left;
}

.roadmap {
  margin-top: calc(variables.$section-margin - 100px);
  text-align: center;
  width: variables.$section-width - 15vw;
}

.roadmap .left-column {
  text-align: right;
}

.roadmap .left-column h3 {
  margin-right: 65px;
}

.roadmap h2 {
  margin-bottom: 30px;
}

.roadmap .row {
  margin-bottom: calc(variables.$inner-section-margin / 2);
}

.roadmap li {
  color: variables.$paragraph-color;
}

.right-column li {
  text-align: left;
  margin-left: 40px;
}

.vector8-range {
  background-image: url('../assets/Vector8.png');
  background-size: 100vw 40vh;
  background-repeat: no-repeat;
  background-position: top;
  // width: 98vw;
  // height: auto;
  padding-top: calc(variables.$inner-section-margin * 3);
  // padding-left: variables.$inner-section-margin * 2;
}

.token {
  margin-top: 0;
  text-align: center;
  width: 70vw;
}

.token h6 {
  color: variables.$paragraph-color;
}

.token h4 {
  font-family: variables.$font-family;
  color: variables.$heading-color;
  font-size: xx-large;
  margin-top: variables.$inner-section-margin;
}

.token-holder-benefits {
  margin-top: variables.$inner-section-margin;
}

.token-holder-benefits h4{
  font-size: x-large;
  margin-top: 0;
  padding-top: 0;
}

.token-holder-benefits h6{
  width: auto;
}

.token-distribution {
  margin-top: calc(variables.$inner-section-margin / 2);
}

.token-distribution img {
  height: 80%;
  width: 80%;
}

.team {
  margin-top: variables.$section-margin;
  text-align: center;
  width: variables.$section-width;
}

.social {
  margin-top: variables.$section-margin;
  text-align: center;
}

.social .row {
  margin-left: variables.$inner-section-margin * 5;
  margin-right: variables.$inner-section-margin * 5;
}

.newsletter-signup {
  text-align: center;
  margin-top: variables.$inner-section-margin;
}

.footer {
  background: variables.$footer-gradient;
  margin-top: calc(variables.$section-margin / 2);
  padding-bottom: calc(variables.$section-margin / 2);
  text-align: center;
  padding-top: 60px;
}

.footer img {
  width: 30%;
  height: auto;
}

.footer a {
  text-decoration: none;
}

.footer h5 {
  color: variables.$heading-color;
  padding-top: 30px;
}

.model-img {
  width: 55%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .jumbo-text {
    margin-top: calc(variables.$mobile-section-margin / 2);
    width: variables.$mobile-section-width;
  }

  .jumbo-text h1 {
    font-size: 65px !important;
    font-family: "Staatliches", cursive;
    color: variables.$heading-color;
  }

  .jumbo-text h6 {
    width: variables.$mobile-section-width;
    color: variables.$paragraph-color;
  }

  .vector1-range {
    padding-bottom: 0px;
  }

  .features .left-column,
  .token-holder-benefits .left-column {
    border-right: none;
    border-bottom: 1px solid variables.$orangeish-accent-color;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .features {
    margin-top: variables.$mobile-section-margin;
  }

  .roadmap {
    width: 100%;
    margin-top: variables.$mobile-section-margin;
  }

  .roadmap .row {
    margin-bottom: 0;
  }

  .roadmap .left-column {
    text-align: center;
  }
  
  .roadmap .left-column h3 {
    margin-right: 0;
  }

  .token {
    margin-top: variables.$mobile-section-margin;
  }

  .token-distribution img {
    height: 98%;
    width: 98%;
  }

  .vector8-range {
    padding-top: calc(variables.$mobile-section-margin / 3);
  }

  .team .card {
    height: auto;
    margin-bottom: 0;
  }

  .team .subtitle {
    margin-bottom: 0px;
  }

  .social {
    margin-top: variables.$mobile-section-margin;
  }

  .social .row {
    margin-left: 0;
    margin-right: 0;
  }

  .footer {
    margin-top: variables.$mobile-section-margin;
  }

  .footer h5 {
    font-size: medium;
    padding-top: 10px;
  }

  .footer img {
    width: 50px;
    height: auto;
  }

  .model-img {
    width: 80%;
  }

}